import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Dropdown,
  IconButton,
  IDropdownOption,
  Link,
  MessageBar,
  MessageBarType,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { dropdownStyles, stackTokens } from '../azComponents/customComponentControls/dropdownControl.styles';
import CustomRenderLabel from '../azComponents/customComponentControls/customLabelRenderer';
import { IAzureResourceGroup } from '../models/azureResource.Models';
import { AzureManagementService } from '../../../../../services/azureManagementService';
import { RootStore } from '../../../../../redux';
import { useDispatch, useSelector } from 'react-redux';
import {
  setResourceGroup,
  setErrorValidationCheckResourceGroupType,
  setLocation,
} from '../../../../../redux/azureInstance/azureInstanceActions';
import styles, { cancelIcon, iconButtonStyles, textfieldStyles } from '../armStyling/index.styles';
import Locations from './locations';
import * as Type from '../../../../../common/Type';

interface ResourceGroupsProps {}
const ResourceGroups: FC<ResourceGroupsProps> = (props: ResourceGroupsProps): JSX.Element => {
  const intlShape = useIntl();
  const dispatch = useDispatch();
  const [resourceGroupsList, setResourceGroupsList] = React.useState<IAzureResourceGroup[] | undefined>();
  const [newResourceGroupName, setNewResourceGroupName] = React.useState<string>('');
  let selectedResourceGroup = useSelector((state: RootStore) => state.azureInstance.resourceGroup);
  const location = useSelector((state: RootStore) => state.azureInstance.location);

  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [permissionErrorMessage, setPermissionErrorMessage] = React.useState<boolean>(false);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const azureToken = useSelector((state: RootStore) => state.authentication.azureToken);
  const subscription = useSelector((state: RootStore) => state.azureInstance.subscription);

  useEffect(() => {
    getResourceGroupsList();
  }, [subscription]);

  const getResourceGroupsList = async () => {
    let response: any;
    if (subscription?.subscriptionId)
      response = await AzureManagementService.getResourceGroupList(azureToken, subscription.subscriptionId);
    setResourceGroupsList(response);
    setRGErrorMessage(response);
  };

  const refreshResourceGroupsList = () => {
    getResourceGroupsList();
    setErrorMessage('');
    dispatch(setErrorValidationCheckResourceGroupType(false));
  };

  const resourceGroupOptions = useMemo<IDropdownOption[]>(
    () =>
      resourceGroupsList?.map((rg: IAzureResourceGroup, index: number) => ({
        key: rg.id,
        text: rg.name,
        index: index,
      })),
    [resourceGroupsList]
  );

  const onResourceGroupChange = (option: IDropdownOption): void => {
    const rg = resourceGroupsList?.filter((each: IAzureResourceGroup) => option.key === each.id)[0];
    dispatch(setResourceGroup(rg));
    setErrorMessage('');
    dispatch(setErrorValidationCheckResourceGroupType(false));
  };

  const setRGErrorMessage = (response: IAzureResourceGroup[]) => {
    if (!response || response.length === 0) {
      setErrorMessage(intlShape.formatMessage({ id: 'resourcegroup.errormessage' }));
      dispatch(setErrorValidationCheckResourceGroupType(true));
    } else if (response && response.length > 0 && !selectedResourceGroup) {
      setErrorMessage(intlShape.formatMessage({ id: 'resourcegroup.selection.errormessage' }));
      dispatch(setErrorValidationCheckResourceGroupType(true));
    }
  };

  const onRenderLabel = (): JSX.Element => {
    return CustomRenderLabel(
      intlShape,
      'resourceGroups.selectRGDropdown',
      null,
      'resourceGroups.installTooltip',
      null,
      'resourceGroups.accessibility.RGInfoIcon',
      null
    );
  };

  const onNewResourceGroupNameChange = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setNewResourceGroupName(newValue || '');
    },
    []
  );

  const titleId = useId(
    intlShape.formatMessage({
      id: 'arm.createResourceGroup.title',
    })
  );

  const onModalDismiss = () => {
    setNewResourceGroupName('');
    setPermissionErrorMessage(false);
    setLocation(null);
    refreshResourceGroupsList();
  };

  const createResourceGroup = async () => {
    let response: any;
    if (newResourceGroupName && location && subscription?.subscriptionId) {
      const data: Type.CreateResourceGroup | string | undefined = {
        location: location.name,
      };
      response = await AzureManagementService.createNewResourceGroup(
        azureToken,
        subscription.subscriptionId,
        newResourceGroupName,
        data
      );
      if (response) {
        dispatch(setResourceGroup(response));
        setPermissionErrorMessage(false);
        hideModal();
        setErrorMessage(
          intlShape.formatMessage({
            id: 'resourcegroup.selection.errormessage',
          })
        );
        dispatch(setErrorValidationCheckResourceGroupType(true));
      } else setPermissionErrorMessage(true);
    }
  };

  return (
    <>
      <div className={styles.verticalSpacing}>
        <Stack horizontal tokens={stackTokens} verticalAlign="end">
          <div className={styles.flexContainer}>
            <div>
              <Dropdown
                options={resourceGroupOptions}
                selectedKey={selectedResourceGroup ? selectedResourceGroup?.id : null}
                onChanged={onResourceGroupChange}
                errorMessage={errorMessage}
                placeHolder={intlShape.formatMessage({
                  id: 'resourceGroups.placeholder',
                })}
                styles={dropdownStyles}
                onRenderLabel={onRenderLabel}
                label={intlShape.formatMessage({
                  id: 'resourceGroups.selectRGDropdown',
                })}
                ariaLabel={intlShape.formatMessage({
                  id: 'resourceGroups.accessibility.placeholder',
                })}
                role="none"
              />
            </div>
            <div>
              <IconButton
                className={styles.iconSize}
                iconProps={{
                  iconName: 'Refresh',
                }}
                ariaLabel={intlShape.formatMessage({
                  id: 'resourceGroups.refreshEnvironments',
                })}
                onClick={refreshResourceGroupsList}
              />
            </div>
          </div>
        </Stack>

        <Stack horizontal tokens={stackTokens} verticalAlign="end">
          <div className={styles.createNewContainer}>
            <Link className={styles.linkColor} underline={true} onClick={showModal}>
              <FormattedMessage id="destination.createNewLabel" />
            </Link>
          </div>
        </Stack>
      </div>
      <Modal
        titleAriaId={titleId}
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={styles.modalContainer}
        onDismissed={onModalDismiss}
      >
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel={intlShape.formatMessage({
            id: 'arm_close_popupmodal',
          })}
          onClick={hideModal}
        />
        <div className={styles.modalHeader}>
          <span>
            {intlShape.formatMessage({
              id: 'arm.createResourceGroup.title',
            })}
          </span>
        </div>
        <div className={styles.modalBody}>
          <Stack tokens={stackTokens}>
            <TextField
              value={newResourceGroupName}
              ariaLabel={intlShape.formatMessage({
                id: 'destination.accessibility.customControlTextField',
              })}
              onChange={onNewResourceGroupNameChange}
              styles={textfieldStyles}
              label={intlShape.formatMessage({
                id: 'arm_nameRG_popupmodal',
              })}
              required
            />
            <Locations />
            {permissionErrorMessage && (
              <MessageBar messageBarType={MessageBarType.error}>
                {intlShape.formatMessage(
                  {
                    id: 'arm.createResourceGroup.permission.errormessage',
                  },
                  { subscription: subscription?.displayName }
                )}
              </MessageBar>
            )}
          </Stack>
        </div>
        <div className={styles.modalFooter}>
          <PrimaryButton
            text={intlShape.formatMessage({
              id: 'arm_createBtn_popupmodal',
            })}
            onClick={createResourceGroup}
            ariaLabel={intlShape.formatMessage({
              id: 'arm_createRG_popupmodal',
            })}
            className={styles.btnStyles}
            disabled={newResourceGroupName && location ? false : true}
          ></PrimaryButton>
          <PrimaryButton
            text={intlShape.formatMessage({ id: 'buttons.cancel' })}
            onClick={hideModal}
            className={styles.btnStyles}
          ></PrimaryButton>
        </div>
      </Modal>
    </>
  );
};

export default ResourceGroups;
