import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FC, ReactElement, useEffect, useState } from 'react';
import { RootStore } from '../../../redux';
import { StaticContext, useLocation } from 'react-router';
import { styles } from './additionalComponents.styles';
import { RouteComponentProps } from 'react-router-dom';
import { FormattedMessage, useIntl, WrappedComponentProps } from 'react-intl';
import { Text, PrimaryButton, DefaultButton, BaseButton, Button } from '@fluentui/react';
import { commonStyles } from '../commonStyles.styles';
import * as ApiType from '../../../solutionCenterApi/gen/index';
import config from '../../../config';
import { telemetry } from '../../../services/telemetryService';
import { OptionalComponentsList } from '../../../components/lists/optionalComponentsList';
import InstallationStateContainer from '../../../stateContainers/installationStateContainer';
import DeploymentStateContainer from '../../../stateContainers/deploymentStateContainer';
import {
  setParameterDefinitionProvider,
  setSubscription,
  setLocation,
  setResourceGroup,
} from '../../../redux/azureInstance/azureInstanceActions';
import { ParameterDefinitionDictionary } from '../../../common/Type';
import {
  IAzureLocation,
  IAzureResourceGroup,
  IAzureSubscription,
} from '../destination/azureResourceWidget/models/azureResource.Models';
import { checkForAvailabeAzureOptionalComponents } from '../../../utils/azureHelper';
import * as Util from '../../../utils/helper';
import { getRouteUtil, getStepUtil } from '../../../utils/deployHelper';
import { setSelectedOptionalComponents } from '../../../redux/deployments/deploymentActions';

export interface AdditionalComponentsProps
  extends RouteComponentProps<
      {},
      StaticContext,
      { from: { pathname: string } | string; deploymentDefinition?: ApiType.DeploymentDefinitionEntity }
    >,
    WrappedComponentProps {}

export const AdditionalComponents: FC<AdditionalComponentsProps> = (props: AdditionalComponentsProps): ReactElement => {
  const intlShape = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();

  const [selectionDetails, setSelectionDetails] = useState<ApiType.OptionalComponent[]>(
    DeploymentStateContainer.getOptionalComponents() ?? []
  );

  const parameterDefinition: ParameterDefinitionDictionary[] | undefined = useSelector(
    (state: RootStore) => state.azureInstance.parameterDefinitionProvider
  );

  const setParameterDefinition = (dictionary: ParameterDefinitionDictionary[]) =>
    dispatch(setParameterDefinitionProvider(dictionary));
  const setAzureSubscription = (subscription: IAzureSubscription) => dispatch(setSubscription(subscription));
  const setAzureResourceGroup = (resourceGroup: IAzureResourceGroup) => dispatch(setResourceGroup(resourceGroup));
  const setAzureLocation = (location: IAzureLocation) => dispatch(setLocation(location));

  useEffect(() => {
    const routesToCheck = [
      config.routes.installDestinationAzure,
      config.routes.installDestinationAzureTenant,
      config.routes.installDestinationDataverse,
      config.routes.installDestinationCI,
      config.routes.installDeploymentSummary,
    ];
    const sourcePath = props.history.location.state?.from;
    const fromPath = typeof sourcePath === 'string' ? sourcePath : sourcePath.pathname;
    if (routesToCheck.includes(fromPath)) {
      setParameterDefinition(removeOCRowKeysFromParameterDictionary(parameterDefinition!));
    }

    setAzureSubscription(null);
    setAzureResourceGroup(null);
    setAzureLocation(null);

    DeploymentStateContainer.setDeploymentName('');
    dispatch(setSelectedOptionalComponents(selectionDetails));
  }, [selectionDetails]);

  useEffect(() => {
    document.querySelector('h1')?.scrollIntoView();
  }, [location.pathname]);

  const removeOCRowKeysFromParameterDictionary = (
    dictionary?: ParameterDefinitionDictionary[]
  ): ParameterDefinitionDictionary[] => {
    let updatedParameterDictionary: ParameterDefinitionDictionary[] = [];
    if (dictionary) {
      dictionary.forEach((parameterDefinition: ParameterDefinitionDictionary) => {
        if (
          selectionDetails.findIndex(
            (oc: ApiType.OptionalComponent) => parameterDefinition.azureOfferRowKey === oc.rowKey
          ) === -1
        ) {
          updatedParameterDictionary.push(parameterDefinition);
        }
      });
      return updatedParameterDictionary;
    } else {
      return parameterDefinition!;
    }
  };

  const handleCancelClick = () => {
    props.history.push(config.routes.home);
  };

  const handleNextClick = async (
    event:
      | React.MouseEvent<
          HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | BaseButton | Button | HTMLSpanElement,
          MouseEvent
        >
      | undefined
  ): Promise<void> => {
    DeploymentStateContainer.setOptionalComponents(selectionDetails);

    try {
      var parameterDictionary: ParameterDefinitionDictionary[] = await checkForAvailabeAzureOptionalComponents(
        selectionDetails,
        parameterDefinition
      );

      setParameterDefinition(parameterDictionary);
    } catch (error) {
      telemetry.logException(error);
    }
    moveNavigation();
  };

  const moveNavigation = (): void => {
    let nextRoute = getRouteUtil(props.location.pathname, true);

    props.history.push(nextRoute, {
      from: props.location.pathname,
    });
  };

  const handleSkipClick = (
    event:
      | React.MouseEvent<
          HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | BaseButton | Button | HTMLSpanElement,
          MouseEvent
        >
      | undefined
  ): void => {
    DeploymentStateContainer.setOptionalComponents([]);
    dispatch(setSelectedOptionalComponents([]));
    moveNavigation();
  };

  const getOptionalComponents = (): ApiType.OptionalComponent[] => {
    var optionalComponents: ApiType.OptionalComponent[] = [];
    var selectedOffers: ApiType.L03[] = InstallationStateContainer.getSelectedOffers();
    selectedOffers.forEach((offer: ApiType.L03) => {
      if (offer.optionalComponents) {
        offer.optionalComponents.forEach((optionalComponent: ApiType.OptionalComponent) => {
          optionalComponents.push(optionalComponent);
        });
      }
    });

    var uniqueOptionalComponents: ApiType.OptionalComponent[] = [];
    optionalComponents.forEach((oc: ApiType.OptionalComponent) => {
      var index: number = uniqueOptionalComponents.findIndex((item) => item.rowKey === oc.rowKey);
      if (index <= -1) {
        uniqueOptionalComponents.push(oc);
      }
    });

    var uniqueFilteredOptionalComponents: ApiType.OptionalComponent[] =
      Util.filterSampleDataOptionalComponents(uniqueOptionalComponents);

    var sortedUniqueOptionalComponents: ApiType.OptionalComponent[] = uniqueFilteredOptionalComponents.sort(
      (a: ApiType.OptionalComponent, b: ApiType.OptionalComponent) => a.order - b.order
    );

    return sortedUniqueOptionalComponents;
  };

  const title = intlShape.formatMessage(
    {
      id: 'installSubStepper.AdditionalComponents',
    },
    { stepNumber: getStepUtil(props.location.pathname) }
  );

  return (
    <div>
      <div className={styles.additionalComponentsContainer}>
        <div
          role="alert"
          aria-label={intlShape.formatMessage({
            id: 'installStepper.setUpASolution',
          })}
          className={styles.titleContainer}
        >
          <h2 id="titleText" className={styles.titleStyles}>
            {title}
          </h2>
          <div className={styles.subTextStyles}>
            <Text id="subTitleText">
              <FormattedMessage id="additionalComponents.addCapabilities" />
            </Text>
          </div>
        </div>
        <div className={styles.optionalComponentsTableStyle}>
          <OptionalComponentsList
            optionalComponents={
              getOptionalComponents().length !== 0
                ? getOptionalComponents()
                : Util.filterSampleDataOptionalComponents(selectionDetails)
            }
            setSelectionDetails={setSelectionDetails}
            isAdditionalComponentsParent={true}
            deploymentL03s={
              props.location.state?.deploymentDefinition ? props.location.state?.deploymentDefinition?.l03s : undefined
            }
          />
        </div>
      </div>
      <div className={commonStyles.footerContainer}>
        <div className={styles.pageFooterLeftActionSection}>
          <PrimaryButton
            id="nextButton"
            disabled={selectionDetails.length === 0}
            text={intlShape.formatMessage({
              id: 'buttons.next',
            })}
            onClick={(event) => handleNextClick(event)}
          />
          <div className={styles.buttonRightActionSection}>
            <DefaultButton
              id="skipButton"
              text={intlShape.formatMessage({
                id: 'buttons.skip',
              })}
              onClick={(event) => handleSkipClick(event)}
            />
          </div>
        </div>

        <div className={styles.buttonRightActionSection}>
          <DefaultButton
            id="cancelButton"
            onClick={handleCancelClick}
            text={intlShape.formatMessage({
              id: 'buttons.cancel',
            })}
          />
        </div>
      </div>
    </div>
  );
};
